import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { Track } from '@app/shared/models/classes/Track';
import { Observable, Subject } from 'rxjs';
import { IndexDbService } from './indexDb.service';

const listeningHistoryKey = '34asdaaisadisdweqwe54dfisa3i4';

@Injectable()
export class ListeningHistoryService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _listenedTracks: Track[] = [];

  private _listenedTracks$: Subject<Track[]> = new Subject<Track[]>();

  // tslint:disable-next-line:member-ordering
  listenedTracks$: Observable<Track[]> = this._listenedTracks$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(private indexDbService: IndexDbService<Track>) {
    super();

    this.indexDbService.getAllPlayedHistoryData().then(data => {
      if (!__.IsNullOrUndefined(data)) {
        this._listenedTracks = data;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  addListenedTrack(track: Track): void {
    const index = this._listenedTracks.findIndex((q: Track) => q.id === track.id);

    if (index === -1) {
      this._listenedTracks.splice(0, 0, track);
    } else {
      this._listenedTracks.splice(index, 1);
      this._listenedTracks.splice(0, 0, track);
      return;
    }

    this._listenedTracks$.next(this._listenedTracks);
    this.indexDbService.addPlayedHistoryEntry(track);
  }

  isListened(trackId: string): boolean {
    return this._listenedTracks.some((q: Track) => q.id === trackId);
  }

  /**
   * Gets the user favorite track variations.
   * @return The user favorite track variations if the user is not authenticated.
   */
  get listenedTracks(): Track[] {
    return this._listenedTracks;
  }
}