import { BaseModel } from './BaseModel';
import { Image } from './Image';
import { Track } from './Track';

export class Playlist extends BaseModel {
    /**
     * The translated project/playlist name
     */
    displayName: string;

    /**
     * The translated normalized project/playlist name
     */
    normalizedName: string;

    /**
     * The translated project/playlist description
     */
    description: string;

    /**
     * The identifier of the playlist as a url conform string
     */
    slug: string;

    /**
     *  A flag indicating whether the currently logged-in user has marked this as a favorite
     */
    isFavorite: boolean;

    /**
     * The playlist tags. Currently only used for playlists.
     */
    tags: Tag[];

    /**
     * The playlist/project translations
     */
    translations: PlaylistTranslations[] = [];

    /**
     * The playlist/project translations
     */
    playlists_i18n: PlaylistTranslations[] = [];

    /**
     * The playlist images
     */
    images: Image[] = [];

    /**
     * The tracks that belong to the project
     */
    tracks: Track[] = [];

    playlistPublicationStatus: PlaylistPublicationStatus;

}

/**
 * Users who have been invited to the project and their project role
 */
export class InvitedUsers {
    /**
     * The user id
     */
    id: string;

    /**
     * The role name
     */
    roleName: string;

    /**
     * The role id
     */
    roleId: string;
}

export class Tag {
    tag: string;
    languageCode: string;
    normalizedTag: string;
}

export class PlaylistCreateOptions {
    //translations: PlaylistTranslations[];
    tags: string[];
    // type: string = PlaylistType.Playlist;
}

export class PlaylistUpdateOptions {
    translations: PlaylistTranslations[];
    tags: string[];
    type: string = PlaylistType.Playlist;
}

export class PlaylistTranslations {
    displayName: string;
    description: string;
    languageCode: string;
}

export class PlaylistShareCreateOptions {
    emails: string[];
    comment: string;
}

export enum PlaylistType {
    Project = 'Project',
    Playlist = 'Playlist'
}

export enum PlaylistPublicationStatus {
    Private = 'Private',
    Public = 'Public'
}