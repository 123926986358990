import { Injectable } from '@angular/core';
import { __ } from '@app/shared/functions/object.functions';
import { Track } from '@app/shared/models/classes/Track';
import { Observable, Subject } from 'rxjs';
import { BaseService } from '../../base/services';
import { ListeningHistoryService } from './listening-history.service';

// ! TODO: REWORK THIS APPROPRIATELY
@Injectable()
export class MusicPlaylistService extends BaseService {

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    playlist: Track[] = [];

    activeTrack: Track;

    currentIndex: number;

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _playlist$: Subject<Track[]> = new Subject<Track[]>();

    private _activeTrack$: Subject<{ track: Track, index: number }> = new Subject<{ track: Track, index: number }>();

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    // tslint:disable-next-line:member-ordering
    public playlist$: Observable<Track[]> = this._playlist$.asObservable();

    // tslint:disable-next-line:member-ordering
    public activeTrack$: Observable<{ track: Track, index: number }> = this._activeTrack$.asObservable();

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private listeningHistoryService: ListeningHistoryService
    ) {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    setPlaylist(playlist: Track[], activeTrack: { id: string, index: number } = null): void {
        this.playlist = [...playlist];
        this._playlist$.next(this.playlist);

        if (this.playlist.length > 0) {
            if (!__.IsNullOrUndefinedOrEmpty(activeTrack)) {
                const track = playlist.find(q => q.id === activeTrack.id);
                this.startTrack(track, activeTrack.index);
            } else {
                this.startTrack(this.playlist[0]);
            }
        }
    }

    startTrack(track: Track, index: number = 0): void {
        this.activeTrack = track;
        this.currentIndex = index;
        this.listeningHistoryService.addListenedTrack(track);
        this._activeTrack$.next({ track: this.activeTrack, index: this.currentIndex });
    }

    playNext(): void {
        const trackIndex = this.getActiveTrackIndex();

        if (trackIndex !== -1) {
            if (trackIndex < this.playlist.length - 1) {
                if (this.currentIndex < this.activeTrack.variations.length - 1) {
                    this.startTrack(this.playlist[trackIndex], this.currentIndex + 1);
                } else {
                    this.startTrack(this.playlist[trackIndex + 1]);
                }
            } else {
                if (this.currentIndex < this.activeTrack.variations.length - 1) {
                    this.startTrack(this.playlist[trackIndex], this.currentIndex + 1);
                } else {
                    this.startTrack(this.playlist[0]);
                }
            }
        }
    }

    playPrevious(): void {
        const trackIndex = this.getActiveTrackIndex();

        if (trackIndex !== -1) {
            if (trackIndex !== 0) {
                if (this.currentIndex > 0) {
                    this.startTrack(this.playlist[trackIndex], this.currentIndex - 1);
                } else {
                    this.startTrack(this.playlist[trackIndex - 1]);
                }
            } else {
                if (this.currentIndex > 0) {
                    this.startTrack(this.playlist[trackIndex], this.currentIndex - 1);
                } else {
                    this.startTrack(this.playlist[this.playlist.length - 1]);
                }
            }
        }
    }

    addTracksToPlaylist(newTracks: Track[], shouldPlayNewTracks: boolean = false, activeTrack: { id: string, index: number } = null): void {
        const unrepeatingPlaylistTracks = this.playlist.filter(q => newTracks.some(p => p.id === q.id) === false );

        this.playlist = [...unrepeatingPlaylistTracks, ...newTracks];
        this._playlist$.next(this.playlist);

        if (this.playlist.length > 0 && shouldPlayNewTracks === true) {
            if (!__.IsNullOrUndefinedOrEmpty(activeTrack)) {
                const track = newTracks.find(q => q.id === activeTrack.id);

                this.startTrack(track, activeTrack.index);
            } else {
                this.startTrack(this.playlist[this.playlist.length - 1]);
            }
        }
    }

    isActive(id: string, index: number): boolean {
        return this.activeTrack?.id === id && this.currentIndex === index;
    }

    allPlaylistTracksInPlaylist(currentPlaylistId: string): boolean {
        return this.playlist.every(track => track.playlistIds.some(playlistId => currentPlaylistId === playlistId));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    private getActiveTrackIndex(): number {
        return this.playlist.findIndex(q => q.id === this.activeTrack.id);
    }

}