import { Injectable } from "@angular/core";
import Dexie from "dexie";

@Injectable({
  providedIn: 'root',
})
export class IndexDbService<T> extends Dexie {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  public downloadHistory: Dexie.Table<T, string>

  public playedHistory: Dexie.Table<T, string>

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
  ) {
    super('Soundtaxi');

    this.version(1).stores({
      downloadHistory: 'id',
      playedHistory: 'id'
    });

    this.downloadHistory = this.table('downloadHistory');
    this.playedHistory = this.table('playedHistory');
  }

  /**
   * Get all downloaded entities from table
   * @param tableName The table name
   * @returns List of entites
   */
  async getAllDownloadHistoryData(): Promise<T[]> {
    return await this.downloadHistory.toArray();
  }

  /**
 * Get all played entities from table
 * @param tableName The table name
 * @returns List of entites
 */
  async getAllPlayedHistoryData(): Promise<T[]> {
    return await this.playedHistory.toArray();
  }

  /**
   * Get downloaded entity by id
   * @param id Entity id
   * @returns Found entity
   */
  async getDownloadHistoryById(id: string): Promise<T> {
    return await this.downloadHistory.get(id);
  }

  /**
   * Get played entity by id
   * @param id Entity id
   * @returns Found entity
   */
  async getPlayedHistoryById(id: string): Promise<T> {
    return await this.playedHistory.get(id);
  }

  /**
   * Save the given data to the index db
   * @param tableName The table name
   * @param data The data entity T
   * @returns Returns an asynchronous opertation containing the primary key
   */
  async addDownloadHistoryEntry(data: T): Promise<string> {
    return this.downloadHistory.add(data);
  }

  /**
   * Save the given data to the index db
   * @param tableName The table name
   * @param data The data entity T
   * @returns Returns an asynchronous opertation containing the primary key
   */
    async addPlayedHistoryEntry(data: T): Promise<string> {
      return this.playedHistory.add(data);
    }

  /**
   * Removes an entity by id
   * @param tableName The table name
   * @param id The entity id
   * @returns Returns an asynchronous opertation
   */
  async removeDownloadHistoryEntry(id: string): Promise<void> {
    await this.downloadHistory.delete(id);
  }

    /**
   * Removes an entity by id
   * @param tableName The table name
   * @param id The entity id
   * @returns Returns an asynchronous opertation
   */
    async removePlayedHistoryEntry(id: string): Promise<void> {
      await this.playedHistory.delete(id);
    }
}