import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/base/services';
import { Track } from '@app/shared/models/classes/Track';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MusicPlayerService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  isExpanded: boolean = false;

  volumeLevel: number = 100;

  isPlaying: { id: string, isPlaying: boolean } = { id: undefined, isPlaying: false };

  isSimilaritySearchPlaying: boolean = false;

  currentTime: number = 0;

  progress: number = 0;

  hasFinished: boolean = false;

  trackedChanged: Track;

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _expand$: Subject<{ id: string, expand: boolean }> = new Subject<{ id: string, expand: boolean }>();

  private _volumeChanges$: Subject<{ id: string, volume: number }> = new Subject<{ id: string, volume: number }>();

  private _play$: Subject<{ id: string, isPlaying: boolean } > = new Subject<{ id: string, isPlaying: boolean } >();

  private _seekTo$: Subject<{ id: string, seek: number }> = new Subject<{ id: string, seek: number }>();

  private _timeChanges$: Subject<{ id: string, time: number }> = new Subject<{ id: string, time: number }>();

  private _progressChanges$: Subject<{ id: string, progress: number }> = new Subject<{ id: string, progress: number }>();

  private _finish$: Subject<{ id: string, finished: boolean }> = new Subject<{ id: string, finished: boolean }>();

  private _trackChanges$: Subject<{ id: string, track: Track }> = new Subject<{ id: string, track: Track }>();

  private _similaritySearchPlay$: Subject<boolean> = new Subject<boolean>();

  private _trackProjectChanges$: Subject<{ trackId: string, projectIds: string[] }> = new Subject<{ trackId: string, projectIds: string[] }>();

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC PRIVATE DEPENDENT INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  // tslint:disable-next-line:member-ordering
  public expand$: Observable<{ id: string, expand: boolean }> = this._expand$.asObservable();

  // tslint:disable-next-line:member-ordering
  public volumeChanges$: Observable<{ id: string, volume: number }> = this._volumeChanges$.asObservable();

  // tslint:disable-next-line:member-ordering
  public play$: Observable<{ id: string, isPlaying: boolean }> = this._play$.asObservable();

  // tslint:disable-next-line:member-ordering
  public seekTo$: Observable<{ id: string, seek: number }> = this._seekTo$.asObservable();

  // tslint:disable-next-line:member-ordering
  public timeChanges$: Observable<{ id: string, time: number }> = this._timeChanges$.asObservable();

  // tslint:disable-next-line:member-ordering
  public progressChanges$: Observable<{ id: string, progress: number }> = this._progressChanges$.asObservable();

  // tslint:disable-next-line:member-ordering
  public finish$: Observable<{ id: string, finished: boolean }> = this._finish$.asObservable();

  // tslint:disable-next-line:member-ordering
  public trackChanges$: Observable<{ id: string, track: Track }> = this._trackChanges$.asObservable();

  // tslint:disable-next-line:member-ordering
  public similaritySearchPlay$: Observable<boolean> = this._similaritySearchPlay$.asObservable();

  // tslint:disable-next-line:member-ordering
  public trackProjectChanges$: Observable<{ trackId: string, projectIds: string[] }> = this._trackProjectChanges$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor() {
    super();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  toggleMusicPlayer(id: string, isExpanded: boolean): void {
    this.isExpanded = isExpanded;
    this._expand$.next({ id, expand: isExpanded });
  }

  setVolumeLevel(id: string, volumeLevel: number): void {
    this.volumeLevel = volumeLevel;
    this._volumeChanges$.next({ id, volume: volumeLevel });
  }

  setIsPlaying(id: string, isPlaying: boolean, isSimilaritySearch: boolean = false) {
    if (this.isPlaying.id === id && this.isPlaying.isPlaying === isPlaying) {
      return;
    }

    this.isPlaying = { id, isPlaying };

    this._play$.next(this.isPlaying);
  }

  // togglePlay(id: string, isSimilaritySearch: boolean = false): void {
  //   if (isSimilaritySearch === false) {
  //     // Stop similarity search player
  //     this.isSimilaritySearchPlaying = false;
  //     this._similaritySearchPlay$.next(this.isSimilaritySearchPlaying);

  //     this.isPlaying = { id, isPlaying: !this.isPlaying?.isPlaying };

  //     this._play$.next(this.isPlaying);
  //     return;
  //   }
  //   // Stop music player
  //   this.isPlaying = { id, isPlaying: false };
  //   this._play$.next(this.isPlaying);

  //   // Close music player
  //   this.toggleMusicPlayer(id, false);

  //   this.isSimilaritySearchPlaying = !this.isSimilaritySearchPlaying;
  //   this._similaritySearchPlay$.next(this.isSimilaritySearchPlaying);
  // }

  pause(id: string, isSimilaritySearch: boolean = false): void {
    if (isSimilaritySearch === false) {

      this.isPlaying = { id, isPlaying: false };
      this._play$.next(this.isPlaying);
      return;
    }
    this.isSimilaritySearchPlaying = false;
    this._similaritySearchPlay$.next(this.isSimilaritySearchPlaying);
  }

  play(id: string, isSimilaritySearch: boolean = false): void {
    if (isSimilaritySearch === false) {
      // Stop similarity search player
      this.isSimilaritySearchPlaying = false;
      this._similaritySearchPlay$.next(this.isSimilaritySearchPlaying);

      this.isPlaying = { id, isPlaying: true };
      this._play$.next(this.isPlaying);
      return;
    }
    // Stop music player
    this.isPlaying = { id, isPlaying: false };
    this._play$.next(this.isPlaying);

    // Close music player
    this.toggleMusicPlayer(id, false);

    this.isSimilaritySearchPlaying = true;
    this._similaritySearchPlay$.next(this.isSimilaritySearchPlaying);
  }

  setTime(id: string, time: number): void {
    let changed = time !== this.currentTime;
    this.currentTime = time;
    if (changed === true) {
      this._timeChanges$.next({ id, time });
    }
  }

  // duplicate for setTime
  setProgress(id: string, progress: number): void {
    let changed = progress !== this.progress;
    this.progress = progress;

    if (changed === true) {
      this._progressChanges$.next({ id, progress });
    }
  }

  seekTo(id: string, progress: number): void {
    this._seekTo$.next({ id, seek: progress });
  }

  setHasFinished(id: string, hasFinished: boolean): void {
    this.hasFinished = hasFinished;
    this._finish$.next({ id, finished: hasFinished });
  }

  changeTrack(id: string, track: Track): void {
    this.trackedChanged = track;
    this._trackChanges$.next({ id, track: this.trackedChanged });
  }

  updateProjectsForTrack(trackId: string, projectIds: string[]): void {
    this._trackProjectChanges$.next({ trackId, projectIds });
  }
}
